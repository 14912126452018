<template>
  <div class="ticheng-form-wrap">
    <div class="yeji-form">
      <div class="yj-label">
        方案名称：
      </div>
      <div class="yj-value">
        {{ addRuleInfo.ruleName }}
      </div>
    </div>

    <div class="yeji-form">
      <div class="yj-label">
        提成计算类型：
      </div>
      <div class="yj-value">
        <template v-if="addRuleInfo.calKind == 1">
          每单计算
        </template>
        <template v-if="addRuleInfo.calKind == 2">
          阶段式计算
        </template>
        <template v-if="addRuleInfo.calKind == 3">
          阶梯式计算
        </template>
      </div>
    </div>
    <div class="yeji-form ">
      <div class="yj-label">
        提成规则配置：
      </div>
      <div class="yj-value peizhi-value">
        <div class="titles">
          <div class="num">序号</div>
          <div>品类</div>
          <div class="price">提成类型</div>
          <div class="price">提成金额</div>
          <div>操作</div>
        </div>

        <div class="peizhi-list">
          <template :key="item" v-for="(item, index) in serveCategoryList">
            <div class="item">
              <div class="num">{{ index + 1 }}</div>
              <div>{{ item.kindName }}</div>
              <div class="price">
                <el-select size="small" v-model="guizePeizhiForm[index].tctype" class="m-2" placeholder="提成类型">
                  <el-option v-for="item in tichengType" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
              <div class="price">
                <el-input size="small" v-model="guizePeizhiForm[index].tcprice" placeholder="提成金额">
                  <template #suffix>
                    <template v-if="guizePeizhiForm[index].tctype == 2">
                      | %
                    </template>

                    <template v-else>
                      | 元
                    </template>
                  </template>
                </el-input>
              </div>
              <div>
                <el-button @click="handleProductSetting(item.kindId)" size="small" plain>特殊产品设置
                  ({{ (tesuForm.filter(ele => ele.cid == item.kindId && String(ele.tctype).length > 0 &&
                      String(ele.tcprice).length > 0)).length
                  }})
                </el-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>


    <div v-if="countType == 2" class="yeji-form ">
      <div class="yj-label">
        阶段区间配置：
      </div>
      <div class="yj-value jd-peizhi-value">
        <div class="titles">
          <div class="num">序号</div>
          <div class="qujian">业绩区间</div>
          <div class="type">提成类型</div>
          <div class="price">提成金额</div>
          <div class="opt"></div>
        </div>

        <div class="peizhi-list">
          <template :key="item" v-for="(item, index) in jiduanListForm">
            <jieduanForm @handelRemoveClick="handelRemoveClick" @handelAddClick="handelAddClick" :index="index"
              :item="item"></jieduanForm>
          </template>
        </div>
      </div>
    </div>

    <div v-if="countType == 3" class="yeji-form ">
      <div class="yj-label">
        阶梯式区间配置：
      </div>
      <div class="yj-value jd-peizhi-value">
        <div class="titles">
          <div class="num">序号</div>
          <div class="qujian">业绩区间</div>
          <div class="type">提成类型</div>
          <div class="price">提成金额</div>
          <div class="opt"></div>
        </div>

        <div class="peizhi-list">
          <template :key="item" v-for="(item, index) in jietiForm">
            <jietiForm @handelRemoveClick="jietiRemoveClick" @handelAddClick="jietiAddClick"
              :isLast="index == jietiForm.length - 1" :index="index" :item="item"></jietiForm>
          </template>
        </div>
      </div>
    </div>

    <div class="yeji-form">
      <div class="yj-label">

      </div>
      <div class="yj-value save-from">
        <el-button @click="handleSaveClick" size="large" color="#2A82E4" type="primary">保存方案</el-button>
        <el-button @click="handlePreviousClick" plain size="large">返回上一步</el-button>
      </div>
    </div>
    <distribute @handleSaveClick='handleDistributeSaveClick' :tesuForm="tesuForm" v-if="dialogVisible"
      @changeRuleForm='changeRuleForm' :cid="currentCid" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"></distribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { tichengType } from './config'
import jieduanForm from './cpns/jieduan-form/jieduan-form'
import jietiForm from './cpns/jieti-form/jieti-form.vue'
import distribute from './cpns/distribute-dialog/distribute-dialog.vue'
import { useRoute } from 'vue-router'
import { getServeCategoryList, addRuleSaveRule, addRuleSaveJieDuanRule, addRuleSaveCardRule } from '@/service/main/commission'
import { COMPANY_ID } from '@/common/constant'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import jieduanFormVue from '../../../sell/cpns/ticheng-form/cpns/jieduan-form/jieduan-form.vue'


export default defineComponent({
  props: {
    countType: {
      type: Number,
      required: true
    },
    ruleId: {
      type: Number,
      default: -1
    },
    addRuleInfo: {
      type: Object,
      required: true
    }
  },
  components: {
    jieduanForm,
    jietiForm,
    distribute
  },
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()

    const jiduanListFormList = ref([])
    const jiduanListForm = ref([{
      yjstart: '',
      yjend: '',
      tctype: '',
      tcprice: '',
    }])

    const handelAddClick = () => {
      jiduanListForm.value.push({
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      })
    }



    const jietiForm = ref([
      {
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      },
      {
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
      }
    ])

    const rulePeizhiForm = ref([])

    const handelRemoveClick = (index) => {
      jiduanListForm.value.splice(index, 1)
    }

    const jietiRemoveClick = (index) => {
      jietiForm.value.splice(index, 1)
    }

    const handlePreviousClick = () => {
      emit('previousClick')
    }

    const jietiAddClick = () => {
      jietiForm.value.push({
        yjstart: '',
        yjend: '',
        tctype: '',
        tcprice: '',
        // ruleId: '',
        // cataStr: '',
        // specStr: '',
        ruleKind: route.query.ruleKind == 1 || route.query.ruleKind == 3 ? 1 : 2
      })
    }


    const guizePeizhiFormList = ref([])
    const tesuFormList = ref([])
    const jietiFormList = ref([])
    const handleSaveClick = async () => {
      guizePeizhiFormList.value = []
      tesuFormList.value = []
      jiduanListFormList.value = []
      jietiFormList.value = []
      let flag1 = true, flag2 = true, flag3 = true;

      guizePeizhiForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            flag1 = false
            break;
          }
        }
        if (flag) {
          guizePeizhiFormList.value.push(item)
        }
      })


      if (flag1 == false) {
        ElMessage({
          message: '请完整填写提成规则配置!',
          type: 'warning',
        })
        return
      }

      tesuForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            break;
          }
        }
        if (flag) {
          tesuFormList.value.push(item)
        }
      })

      jiduanListForm.value.forEach(item => {
        let flag = true
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag = false
            flag2 = false
            break;
          }
        }
        if (flag) {
          jiduanListFormList.value.push(item)
        }
      })

      if (flag2 == false && props.countType == 2) {
        ElMessage({
          message: '请完整填写阶段式区间配置!',
          type: 'warning',
        })
        return
      }

      jietiForm.value.forEach(item => {
        for (let key in item) {
          if (String(item[key]).length == 0) {
            flag3 = false
            break;
          }
        }
      })

      if (flag3 == false && props.countType == 3) {
        ElMessage({
          message: '请完整填写阶梯式区间配置!',
          type: 'warning',
        })
        return
      }




      if (jietiForm.value.length > 2) {
        jietiForm.value[0].yjstart = '0'
        jietiForm.value[jietiForm.value.length - 1].yjend = '0'
        jietiForm.value.forEach(item => {
          let flag = true
          for (let key in item) {
            if (String(item[key]).length == 0) {
              flag = false
              break;
            }
          }
          if (flag) {
            jietiFormList.value.push(item)
          }
        })
      } else {
        jietiForm.value[0].yjstart = '0'
        jietiForm.value[jietiForm.value.length - 1].yjend = '0'
        jietiFormList.value = jietiForm.value
      }


      let obj = {
        itemkind: 1,
        ruleId: props.ruleId,
        cataStr: [],
        specStr: [],
        ruleKind: route.query.ruleKind == 1 || route.query.ruleKind == 3 ? 1 : 2
      }
      guizePeizhiFormList.value.forEach(item => {
        obj.cataStr.push(`${item.cid},${item.tctype},${item.tcprice}`)
      })

      tesuFormList.value.forEach(item => {
        obj.specStr.push(`${item.cid},${item.serveId},${item.tctype},${item.tcprice}`)
      })

      obj.cataStr = obj.cataStr.join('&')
      obj.specStr = obj.specStr.join('&')

      const res = await addRuleSaveCardRule(obj)
      let flag = true
      if (res.code != 0) {
        flag = false
      }

      if (props.countType == 2) {
        let obj = {
          ruleId: props.ruleId,
          companyId: COMPANY_ID,
          rangeStr: [],
          calKind: 1
        }
        // console.log(jiduanListFormList.value);
        jiduanListFormList.value.forEach(item => {
          obj.rangeStr.push(`${item.yjstart},${item.yjend},${item.tctype},${item.tcprice}`)
        })
        obj.rangeStr = obj.rangeStr.join('&')
        const res = await addRuleSaveJieDuanRule(obj)
        if (res.code != 0) {
          flag = false
        }
      }

      if (props.countType == 3) {
        let obj = {
          ruleId: props.ruleId,
          companyId: COMPANY_ID,
          rangeStr: [],
          calKind: 2
        }
        // console.log(jietiFormList.value);
        jietiFormList.value[0].yjstart = 0
        jietiFormList.value[(jietiFormList.value.length) - 1].yjend = 0
        jietiFormList.value.forEach(item => {
          obj.rangeStr.push(`${item.yjstart},${item.yjend},${item.tctype},${item.tcprice}`)
        })
        obj.rangeStr = obj.rangeStr.join('&')
        const res = await addRuleSaveJieDuanRule(obj)
        if (res.code != 0) {
          flag = false
        }
      }


      if (flag) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
        router.push('/main/commission/list')
      } else {
        ElMessage.error('操作失败,请刷新')
      }
    }





    const dialogVisible = ref(false)
    const changeDialogVisible = (flag) => {
      dialogVisible.value = flag
    }


    const currentCid = ref('')
    const handleProductSetting = (cid) => {
      // console.log(cid);
      currentCid.value = cid
      dialogVisible.value = true
    }


    const serveCategoryList = ref([
      {
        kindName: '有限次卡',
        kindId: 3
      }, {
        kindName: '不限次卡',
        kindId: 4
      },
      {
        kindName: '储值卡',
        kindId: 5
      }
    ])


    const guizePeizhiForm = ref([
      {
        tcprice: '',
        tctype: '',
        cid: 3
      },
      {
        tcprice: '',
        tctype: '',
        cid: 4
      }, {
        tcprice: '',
        tctype: '',
        cid: 5
      }

    ])

    const initPage = async () => {

    }
    initPage()


    const tesuForm = ref([])
    const changeRuleForm = (list) => {
      list.forEach(item => {
        const index = tesuForm.value.findIndex(ele => ele.serveId == item.serveId)

        if (index == -1) {
          tesuForm.value.push(item)
        } else {
          tesuForm.value[index].tcprice = item.tcprice
          tesuForm.value[index].tctype = item.tctype
        }
      })

    }

    const handleDistributeSaveClick = () => {
      dialogVisible.value = false
    }

    return {
      handleDistributeSaveClick,
      jietiFormList,
      jiduanListFormList,
      tesuFormList,
      guizePeizhiFormList,
      tesuForm,
      changeRuleForm,
      currentCid,
      dialogVisible,
      changeDialogVisible,
      handleProductSetting,
      jietiRemoveClick,
      jietiAddClick,
      handlePreviousClick,
      tichengType,
      jiduanListForm,
      handelAddClick,
      handelRemoveClick,
      jietiForm,
      serveCategoryList,
      guizePeizhiForm,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.ticheng-form-wrap {
  padding-bottom: 82px;

  .yeji-form-center {
    align-items: center;
  }



  .yeji-form {
    display: flex;
    margin-bottom: 27px;
    font-size: 14px;

    .yj-label {
      width: 120px;
      font-weight: bold;
      color: rgba(80, 80, 80, 1);

    }

    .yj-value {
      flex: 1;

    }

    .jd-peizhi-value {
      max-width: 700px;

      .titles {
        display: flex;
        height: 36px;
        line-height: 36px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 14px;
        padding: 0 18px;

        .num {
          flex: .6;
        }

        .qujian {
          flex: 2;
        }

        .type {
          flex: 1.2;
        }

        .price {
          flex: 1.2;
        }

        .opt {
          flex: .8;
        }
      }
    }

    .peizhi-value {
      max-width: 700px;

      &:deep(.el-select) {
        max-width: 120px;
      }

      &:deep(.el-input) {
        max-width: 120px;
      }

      .peizhi-list {


        .item {
          display: flex;
          line-height: 60px;
          height: 60px;
          border-bottom: 1px solid rgba(204, 204, 204, .6);
          padding: 0 18px;

          .num {
            flex: .6;
          }

          &>div {
            flex: 1;
          }

          .price {
            flex: 1.4;
          }
        }
      }

      .titles {
        display: flex;
        height: 36px;
        line-height: 36px;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 14px;
        padding: 0 18px;

        .num {
          flex: .6;
        }

        .price {
          flex: 1.4;
        }

        &>div {
          flex: 1;
        }
      }
    }

    .save-from {
      display: flex;
      align-items: center;
      margin-top: 64px;

      &:deep(.el-button) {
        width: 213px;
        height: 48px;
      }

      .tips {
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        margin-left: 19px;
      }
    }


  }
}
</style>




