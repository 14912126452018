export const tichengType = [
  {
    label: '固定',
    value: '1'
  },
  {
    label: '百分比',
    value: '2'
  }
]
