<template>
  <div class="jieduan-form">
    <div class="num">{{ index + 1 }}</div>
    <div class="qujian">
      <el-input :disabled="index == 0" size="small" v-model="item.yjstart" :placeholder="index == 0 ? '0' : '请输入数字'" />
      <span>-</span>
      <el-input :disabled="isLast ? true : false" size="small" v-model="item.yjend" :placeholder="isLast ? '∞' : '请输入数字'" />
    </div>
    <div class="type">
      <el-select size="small" v-model="item.tctype" class="m-2" placeholder="提成类型">
        <el-option v-for="item in tichengType" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
    </div>
    <div class="price">
      <el-input size="small" v-model="item.tcprice" placeholder="提成金额">
        <template #suffix>
         <template v-if="item.tctype==2">
           | %
          </template>
          <template v-else>
            | 元
          </template>
        </template>
      </el-input>
    </div>
    <div class="opt">
      <div>
        <el-button  @click="handelAddClick" size="small" plain>增加</el-button>
      </div>
      <div>
        <el-button @click="handelRemoveClick(index)" v-if="index != 0 && isLast == false" size="small" type="danger" plain>移除</el-button>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { tichengType } from '../../config'
export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isLast: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  setup(props, { emit }) {

    const handelAddClick = () => {
      emit('handelAddClick')
    }

    const handelRemoveClick = (index) => {
      emit('handelRemoveClick', index)
    }

    return {
      tichengType,
      handelAddClick,
      handelRemoveClick
    }

  }
})
</script>

<style scoped lang="less">
.jieduan-form {
  display: flex;
  max-width: 700px;
  line-height: 60px;
  height: 60px;
  border-bottom: 1px solid rgba(204, 204, 204, .6);
  padding: 0 18px;

  .num {
    flex: .6;
    width: 0;
  }

  &:deep(.el-select) {
    max-width: 120px;
  }

  &:deep(.el-input) {
    max-width: 120px;
  }


  .qujian {
    flex: 2;
    width: 0;

    span {
      display: inline-block;
      margin-right: 6px;
    }

    &:deep(.el-input) {
      max-width: 95px;
      margin-right: 10px;
    }
  }

  .type {
    flex: 1.2;
    width: 0;
  }

  .opt {
    flex: .8;
    display: flex;
    width: 0;

    &>div {
      cursor: pointer;
      color: rgb(42, 130, 228);
      margin-right: 10px;
    }
  }

  .price {
    flex: 1.2;
    width: 0;
  }

}
</style>




