<template>
  <div class="add-rank-dialog">
    <page-dialog :isShowFooter="true" :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="选择门店">
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
        </div>
        <el-scrollbar height="200px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template v-if="ruleKind == 1 ">
              <template :key="item" v-for="item in optDataList">
                <el-checkbox :label="item.shopId">
                  <div class="item">
                    <div class="left">
                      <div class="name">{{ item.shopName }}</div>
                    </div>
                  </div>
                </el-checkbox>
              </template>
            </template>

            <template v-else>
              <template :key="item" v-for="item in optDataList">
                <el-checkbox :label="item.lvId">
                  <div class="item">
                    <div class="left">
                      <div class="name">{{ item.levelName }}</div>
                    </div>
                  </div>
                </el-checkbox>
              </template>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>

      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { getRoleList, setRuleBind, getRuleBindList } from '@/service/main/commission'
import { ElMessage } from 'element-plus'
import { getunArchShopsList, getunArchRolesList } from '@/service/main/commission'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    ruleId: {
      type: Number,
      default: -1
    },
    ruleKind: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList', 'changeOptShop'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const ruleType = computed(() => props.ruleKind)
    const optDataList = ref()
    const shopCount = ref(0)

    const shopListInfo = ref([])
    const handleChange = value => {
      // console.log(value);

    }

    const checkList = ref([])
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    const getBindList = async () => {
      checkList.value = []
      let bindRes = await getRuleBindList(props.ruleId)
      bindRes.data.list.forEach(item => {
        checkList.value.push(item.relateId)
      })
    }


    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }
    const initPage = async () => {
      let type = ruleType.value
      let res
      if (type == 1 || type == 2) {
        res = await getunArchShopsList()
      } else {
        res = await getunArchRolesList()
      }
      optDataList.value = res.data.list
    }
    initPage()



    const handleCheckChange = (value) => {
      let type = ruleType.value
      let optNameArr = []
      if (type == 1 || type == 2) {
        value.forEach(ele => {
          let item = optDataList.value.find(elem => elem.shopId == ele)
          if (item != undefined) {
            optNameArr.push(item.shopName)
          }
        })
      } else {
        value.forEach(ele => {
          let item = optDataList.value.find(elem => elem.lvId == ele)
          if (item != undefined) {
            optNameArr.push(item.levelName)
          }
        })
      }

      emit('changeOptShop', {
        ids: value,
        optNameArr: optNameArr
      })

    }

    watch(() => props.ruleId, (n) => {
      getBindList()
    }, {
      deep: true
    })

    const handleSaveClick=()=>{
      changeDialogVisible(false)
    }
    const searchValue = ref('')

    return {
      handleSaveClick,
      ruleType,
      handleChange,
      shopListInfo,
      searchValue,
      changeDialogVisible,
      rankCategory,
      optDataList,
      shopCount,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckChange,
      currentEditShop,
      // shopCardBindList,
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;


          }
        }
      }
    }
  }
}
</style>




